import { useCurrentUser } from "@spesill/hooks";
import { LearningDatabase } from "@spesill/models";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";

type UploadFileType = {
  properties: Partial<LearningDatabase>;
  id: string;
  onSuccess?: () => void;
};

type ReturnType = {
  updateLearningDatabase: ({
    properties,
    id,
    onSuccess,
  }: UploadFileType) => Promise<{ id: string } | null>;
};

export const useUpdateLearningDatabase = (
  learningDatabase: LearningDatabase,
): ReturnType => {
  const { currentUser } = useCurrentUser();

  const updateLearningDatabase = async ({
    properties,
    id,
    onSuccess,
  }: UploadFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.id) return null;
    const learningDatabaseRepository = new LearningDatabaseRepository();
    return learningDatabaseRepository
      .updateById(id, {
        ...learningDatabase,
        ...properties,
        lastUpdateUserId: currentUser.id,
      })
      .then((res) => {
        onSuccess && onSuccess();
        return res;
      });
  };

  return {
    updateLearningDatabase,
  };
};
