export const FILE_MAX_BYTE_SIZE = 1024 * 1024 * 25; //25MB

type AcceptImportFileTypes = {
  [key: string]: string[];
};

export const ACCEPT_DATABASE_IMPORT_FILE_TYPES: AcceptImportFileTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/webp": [".webp"],
};

export const ACCEPT_DOCUMENT_IMPORT_FILE_TYPES: AcceptImportFileTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/pdf": [".pdf"],
};

export const ACCEPT_PUNCH_ICON_FILE_TYPES: AcceptImportFileTypes = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/svg+xml": [".svg"],
};
