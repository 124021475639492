import { useCurrentUser } from "@spesill/hooks";
import { EvaluateDatabase } from "@spesill/models";
import { EvaluateDatabaseRepository } from "@spesill/repository/db/evaluateDatabase.repository";

type UploadFileType = {
  properties: Partial<EvaluateDatabase>;
  id: string;
  onSuccess?: () => void;
};

type ReturnType = {
  updateEvaluateDatabase: ({
    properties,
    id,
    onSuccess,
  }: UploadFileType) => Promise<{ id: string } | null>;
};

export const useUpdateEvaluateDatabase = (
  evaluateDatabase: EvaluateDatabase,
): ReturnType => {
  const { currentUser } = useCurrentUser();

  const updateEvaluateDatabase = async ({
    properties,
    id,
    onSuccess,
  }: UploadFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.id) return null;
    const evaluateDatabaseRepository = new EvaluateDatabaseRepository();
    return evaluateDatabaseRepository
      .updateById(id, {
        ...evaluateDatabase,
        ...properties,
        lastUpdateUserId: currentUser.id,
      })
      .then((res) => {
        onSuccess && onSuccess();
        return res;
      });
  };

  return {
    updateEvaluateDatabase,
  };
};
