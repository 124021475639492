import React from "react";
import { FileRejection } from "react-dropzone";

import { Button } from "@spesill/components/atoms";
import { ACCEPT_DOCUMENT_IMPORT_FILE_TYPES } from "@spesill/components/atoms/DropzoneFileField/constants";
import { DocumentImportForm } from "@spesill/components/organisms";

type PropsType = {
  handleDropFile: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => void;
  handleDeleteFileByIndex: (index: number) => void;
  file: File | undefined;
  fileUrl: string | undefined;
  onClose: () => void;
  handleImport: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const FormatGeneratorFromUpload = ({
  handleDropFile,
  handleDeleteFileByIndex,
  file,
  fileUrl,
  onClose,
  handleImport,
}: PropsType) => {
  const availableFileTypesText = Object.values(
    ACCEPT_DOCUMENT_IMPORT_FILE_TYPES,
  )
    .flat()
    .join(" / ");
  return (
    <>
      <h4 className="text-h4 text-blueGray-900">文書を作成</h4>
      <p className="my-4">
        フォーマットファイル
        <span className="text-status-danger">
          ( {`${availableFileTypesText}`} )
        </span>
        をアップロードしてください。
      </p>
      <p className="my-4 text-xs text-blueGray-500">
        * docx / xlsx: 文章生成、編集、評価校正機能が利用できます。
        <br />* pdf: 評価校正機能のみ利用できます。
      </p>
      <DocumentImportForm
        handleDropFile={handleDropFile}
        handleDeleteFileByIndex={handleDeleteFileByIndex}
        acceptFileTypes={ACCEPT_DOCUMENT_IMPORT_FILE_TYPES}
        files={
          fileUrl && file
            ? [
                {
                  url: fileUrl,
                  name: file.name || "",
                  size: file.size,
                },
              ]
            : undefined
        }
      />
      <div className="space-x-4 text-right">
        <Button
          text={"キャンセル"}
          color={"gray"}
          variant={"contained"}
          onClick={onClose}
        />
        <Button
          text={"次へ"}
          color={"primary"}
          variant={"contained"}
          onClick={handleImport}
        />
      </div>
    </>
  );
};
