import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import {
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import { useInput, useUpdateDocument } from "@spesill/hooks";
import { Document } from "@spesill/models";

type PropsType = {
  document: Document;
  onClose: () => void;
  refetch?: () => void;
};
export const UpdateDocumentNameModal = ({
  document,
  onClose,
  refetch,
}: PropsType) => {
  const [{ value: systemName, onChange: onChangeSystemName }] = useInput(
    document.systemName,
  );

  const { onUpdateDocument, isRequesting } = useUpdateDocument(
    document,
    refetch,
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!systemName) return;

    try {
      await onUpdateDocument({ systemName });

      toast.success("名前の変更に成功しました。");
      onClose();
    } catch (e) {
      toast.error("名前の変更に失敗しました。");
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="文書タイトルを編集">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <LabelWithTextField
            type="text"
            name="systemName"
            labelText="文書タイトル"
            required
            placeholder="文書タイトルを入力してください。"
            value={systemName}
            onChange={onChangeSystemName}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="保存"
              color="primary"
              variant="contained"
              disabled={isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
