import axios from "axios";
import React, { MouseEvent, useState } from "react";
import { toast } from "react-toastify";

import { useRouter } from "next/router";

import { ModalWrapper } from "@spesill/components/molecules";

import {
  useBoolean,
  useCurrentUser,
  useDocumentTypeDropDownField,
  useFetchTenantDocumentSettings,
  useFile,
  useInput,
  useLanguageDropdownField,
  useStorageUploadFile,
  useTenantLimitManager,
  useWordDocumentsConvertToHtml,
} from "@spesill/hooks";
import { Document, DocumentLanguageType } from "@spesill/models/document";
import {
  getDocumentType,
  getExtension,
  getFilename,
} from "@spesill/utils/fileHelper";

import { FormatGeneratorFromUpload } from "./FormatGenerator/FormatGeneratorFromUpload";
import { FormatPreview } from "./FormatPreview";

export enum DocumentFormatStep {
  FormatUpload = "FormatUpload",
  CreateNew = "CreateNew",
}

type PropsType = {
  onClose: () => void;
};

export const UploadDocumentFormatModal = ({ onClose }: PropsType) => {
  const { currentUser } = useCurrentUser();
  const [currentStep, setCurrentStep] = useState<DocumentFormatStep>(
    DocumentFormatStep.FormatUpload,
  );
  const { convertToHtml, html, setHtml } = useWordDocumentsConvertToHtml();
  const { files, handleDropFile, setFiles } = useFile();
  const selectedFile = files[0];
  const [{ value: systemName, onChange: onChangeSystemName }] = useInput("");
  // const { isChecked: isRequesting } = useBoolean(false);
  const { isUploadable } = useTenantLimitManager();
  const {
    isChecked: isUploading,
    setTrue: setUploadTrue,
    setFalse: setUploadFalse,
  } = useBoolean(false);
  const {
    dropdownValue: dropdownValueDocumentType,
    dropdownSelectableValue: dropdownSelectableValueDocumentType,
    onChange: onChangeDropdownDocumentType,
  } = useDocumentTypeDropDownField();

  const { uploadFile } = useStorageUploadFile();

  const { tenantDocumentSettings } = useFetchTenantDocumentSettings();
  useInput(currentUser?.jobDescription || "");
  const [{ value: freeInputKind, onChange: onChangeFreeInputKind }] =
    useInput("");

  const {
    dropdownValue: dropdownValueLanguage,
    dropdownSelectableValue: dropdownSelectableValueLanguage,
    onChange: onChangeDropdownLanguage,
  } = useLanguageDropdownField();

  const router = useRouter();

  const handleUploadFile = async (currentHtml?: string) => {
    if (!currentUser) return;
    if (!selectedFile) return; // AIの場合はファイルはなくて良い
    if (!systemName) {
      return toast.error("ファイル名を入力してください。");
    }
    if (!dropdownValueLanguage) {
      return toast.error("言語を入力してください。");
    }
    if (!dropdownValueDocumentType) {
      return toast.error("文書の種類を入力してください。");
    }
    if (dropdownValueDocumentType.value === "others" && !freeInputKind) {
      return toast.error("文書の種類を入力してください。");
    }
    const documentType = getDocumentType(selectedFile?.name || "");
    if (!documentType) return toast.error("ファイルの種類が不正です。");
    const documentExtension = getExtension(selectedFile?.name || "");
    if (
      !documentExtension ||
      !Document.prototype.isUploadable(documentExtension)
    ) {
      return toast.error("ファイルの種類が不正です。");
    }
    const fileName = getFilename(systemName, documentExtension);
    if (!fileName) {
      return toast.error("ファイル名が不正です。");
    }

    const canAddFile = await isUploadable(selectedFile.size);
    if (!canAddFile) {
      return toast.error(
        "データ容量がプランの上限に達しました。プランをアップグレードしてください",
      );
    }

    setUploadTrue();
    const uploadStatus = toast.loading(
      "アップロード中です。しばらくお待ちください",
    );

    try {
      const uploadedFile = selectedFile;
      const documentProps = {
        file: uploadedFile,
        fileName: fileName,
        systemName,
        kind: dropdownValueDocumentType.value,
        freeInputKind: freeInputKind,
        tenantName: tenantDocumentSettings?.tenantName || "",
        tenantBusinessDetails: tenantDocumentSettings?.businessDetails || "",
        userDepartment: currentUser.department || "",
        userJobDescription: currentUser.jobDescription || "",
        language: dropdownValueLanguage?.value as DocumentLanguageType,
        onSuccess: async (document: Document) => {
          await axios.post("/api/mail/notification", {
            notificationType: "create-Document",
            systemName,
            operatorUserId: currentUser.id,
            resourceId: document.id,
          });
          toast.update(uploadStatus, {
            render: "アップロードが完了しました。",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        },
      };

      if (!currentHtml && documentType === "word") {
        return toast.error("ファイルが不正です");
      }

      const uploadProps = (() => {
        switch (documentType) {
          case "word":
            return {
              ...documentProps,
              documentType: "word" as const,
              html: currentHtml || "",
            };
          case "excel":
            return {
              ...documentProps,
              documentType: "excel" as const,
              html: undefined,
            };
          case "pdf":
            return {
              ...documentProps,
              documentType: "pdf" as const,
              html: undefined,
            };
          default:
            return undefined;
        }
      })();

      if (!uploadProps) {
        return toast.error("ファイルが不正です");
      }

      const document = await uploadFile(uploadProps);

      if (document) {
        router.push(`/documents/${document.id}`);
        onClose();
      } else {
        toast.dismiss(uploadStatus);
        toast.error("アップロードに失敗しました。");
      }
    } catch (e) {
      toast.dismiss(uploadStatus);
      toast.error(
        e instanceof Error ? e.message : "アップロードに失敗しました。",
      );
    } finally {
      setUploadFalse();
    }
  };

  const handleImport = (e: MouseEvent<HTMLButtonElement>) => {
    if (!selectedFile) return toast.error("ファイルを選択してください。");
    e.preventDefault();
    const extension = getExtension(selectedFile?.name);
    // wordの場合のみhtmlに変換する.
    if (extension === "docx") {
      convertToHtml(selectedFile);
    }
    setCurrentStep(DocumentFormatStep.CreateNew);
  };

  const handleResetFile = () => {
    setFiles([]);
    setHtml(undefined);
  };

  return (
    <ModalWrapper onClose={onClose} className="min-w-[80vw]" fullContentWidth>
      {(() => {
        switch (currentStep) {
          case DocumentFormatStep.FormatUpload:
            return (
              <FormatGeneratorFromUpload
                handleDeleteFileByIndex={handleResetFile}
                handleDropFile={handleDropFile}
                file={selectedFile}
                fileUrl={
                  selectedFile ? URL.createObjectURL(selectedFile) : undefined
                }
                onClose={onClose}
                handleImport={handleImport}
              />
            );
          case DocumentFormatStep.CreateNew:
            return (
              <FormatPreview
                onBack={() => setCurrentStep(DocumentFormatStep.FormatUpload)}
                handleUploadFile={handleUploadFile}
                systemName={systemName}
                onChangeSystemName={onChangeSystemName}
                html={html}
                file={selectedFile}
                isUploading={isUploading}
                dropdownValueDocumentType={dropdownValueDocumentType}
                dropdownSelectableValueDocumentType={
                  dropdownSelectableValueDocumentType
                }
                dropdownValueLanguage={dropdownValueLanguage}
                dropdownSelectableValueLanguage={
                  dropdownSelectableValueLanguage
                }
                onChangeDropdownLanguage={onChangeDropdownLanguage}
                onChangeFreeInputKind={onChangeFreeInputKind}
                freeInputKind={freeInputKind}
                onChangeDropdownDocumentType={onChangeDropdownDocumentType}
                documentType={
                  selectedFile?.name
                    ? getDocumentType(selectedFile.name)
                    : "word"
                }
              />
            );
        }
      })()}
    </ModalWrapper>
  );
};
