import { useCallback } from "react";

import { useBoolean, useCurrentUser } from "@spesill/hooks";
import { Document } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";

type ReturnType = {
  onUpdateDocument: (updatedProperties: Partial<Document>) => Promise<void>;
  isRequesting: boolean;
};
export const useUpdateDocument = (
  document?: Document,
  onSuccess?: () => void,
): ReturnType => {
  const { isChecked: isRequesting, setTrue, setFalse } = useBoolean(false);
  const { currentUser } = useCurrentUser();
  const onUpdateDocument = useCallback(
    async (updatedProperties: Partial<Document>) => {
      if (!document) return;
      if (!currentUser?.id) return;
      const documentRepository = new DocumentRepository();
      setTrue();
      await documentRepository.updateById(document.id, {
        ...document,
        ...updatedProperties,
        lastUpdateUserId: currentUser.id,
      });
      onSuccess && onSuccess();
      setFalse();
    },
    [currentUser, document, onSuccess, setFalse, setTrue],
  );

  return {
    onUpdateDocument,
    isRequesting,
  };
};
