import { currentFormatTimestamp } from "@spesill/libs/dateFns";
import { DocumentType } from "@spesill/models/document";

export const getExtension = (filename: string, returnWithDot?: boolean) => {
  const parts = filename.split(".");
  if (returnWithDot) {
    return `.${parts[parts.length - 1]}`;
  } else {
    return parts[parts.length - 1];
  }
};

export const getFilename = (
  systemName: string,
  extension: "docx" | "xlsx" | "pdf",
) => {
  return `${systemName}_${currentFormatTimestamp()}.${extension}`;
};

export function getDocumentType(fileName: string): DocumentType {
  const extension = fileName.split(".").pop()?.toLowerCase();
  switch (extension) {
    case "xlsx":
      return "excel";
    case "docx":
      return "word";
    case "pdf":
      return "pdf";
    default:
      return "word";
  }
}

export const getFileFromUrl = async ({
  url,
  filename,
}: {
  url: string;
  filename?: string;
}) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: data.type,
  };

  return new File([data], filename || "file", metadata);
};
