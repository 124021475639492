import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import {
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import { useInput, useUpdateEvaluateDatabase } from "@spesill/hooks";
import { EvaluateDatabase } from "@spesill/models";

type PropsType = {
  evaluateDatabase: EvaluateDatabase;
  onClose: () => void;
  refetch?: () => void;
};
export const UpdateEvaluateDatabaseNameModal = ({
  evaluateDatabase,
  onClose,
  refetch,
}: PropsType) => {
  const [{ value: systemName, onChange: onChangeSystemName }] = useInput(
    evaluateDatabase.systemName,
  );
  const { updateEvaluateDatabase } =
    useUpdateEvaluateDatabase(evaluateDatabase);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!systemName) return;

    try {
      await updateEvaluateDatabase({
        id: evaluateDatabase.id,
        properties: { systemName },
      });

      toast.success("名前の変更に成功しました。");
      refetch && refetch();
      onClose();
    } catch (e) {
      toast.error("名前の変更に失敗しました。");
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="データベース名を編集">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <LabelWithTextField
            type="text"
            name="systemName"
            labelText="データベース名"
            required
            placeholder="データベース名を入力してください。"
            value={systemName}
            onChange={onChangeSystemName}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton text="保存" color="primary" variant="contained" />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
