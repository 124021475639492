import React, { MouseEvent, useMemo } from "react";
import { toast } from "react-toastify";

import { Checkbox, Icon, Tooltip } from "@spesill/components/atoms";
import {
  IconButton,
  ListMenu,
  MenuItemType,
} from "@spesill/components/molecules";
import { DocumentPreviewModal } from "@spesill/components/organisms";

import { useBoolean, useHover } from "@spesill/hooks";
import { formatDate } from "@spesill/libs";
import { LearningDocument, User } from "@spesill/models";

type PropsType = {
  learningDocument: LearningDocument & { user?: User };
  deleteDocument: (id: string) => void;
  error?: string;
  isSelected: boolean;
  onSelect: (id: string) => void;
};

const statusMapper = {
  learned: { color: "text-cyan-600", text: "学習済" },
  unlearned: { color: "text-blueGray-300", text: "未学習" },
  error: { color: "text-status-danger", text: "学習失敗" },
};

const getStatus = (
  learnedAt?: Date,
  error?: string,
): "error" | "unlearned" | "learned" => {
  if (learnedAt) {
    return "learned";
  }
  if (error) {
    return "error";
  }
  return "unlearned";
};

export const LearningDocumentListRow = ({
  learningDocument,
  deleteDocument,
  error,
  isSelected,
  onSelect,
}: PropsType) => {
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  const {
    isChecked: isOpenDocumentPreview,
    setTrue: onOpenDocumentPreview,
    setFalse: onCloseDocumentPreview,
  } = useBoolean(false);

  const [ref] = useHover<HTMLDivElement>();

  const handleDeleteDocument = async () => {
    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;

    await deleteDocument(learningDocument.id);
    toast.success("削除しました");
  };

  const menuItems: MenuItemType[] = [
    {
      label: "削除",
      className: "text-status-danger",
      onClick: () => handleDeleteDocument(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpen();
  };

  const handleOpenDocumentPreview = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpenDocumentPreview();
  };

  const status = useMemo(
    () => getStatus(learningDocument.learnedAt, error),
    [learningDocument.learnedAt, error],
  );

  return (
    <>
      <div
        ref={ref}
        className="bg-white flex text-blueGray-700 py-3 px-4 rounded-md h-16 items-center hover:bg-blueGray-50"
      >
        <Checkbox
          className="mx-3"
          size="sm"
          checked={isSelected}
          onChange={() => onSelect(learningDocument.id)}
          name={"learningDocument"}
        />
        <button
          type="button"
          onClick={(e) => handleOpenDocumentPreview(e)}
          className="flex-1 gap-x-4 flex items-center"
        >
          <div className="flex items-center space-x-2 flex-1 gap-x-4">
            {learningDocument.isExcel() ? (
              <Icon
                icon="riFileExcel2Fill"
                size="1.8rem"
                color="text-green-700"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            ) : learningDocument.isPdf() ? (
              <Icon
                icon="tbPdf"
                size="1.8rem"
                color="text-status-danger"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            ) : learningDocument.isImage() ? (
              <Icon
                icon="faImage"
                size="1.8rem"
                color="text-blueGray-500"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            ) : (
              <Icon
                icon="riFileWord2Fill"
                size="1.8rem"
                color="text-primary-400"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            )}
            <span className="text-body-base-bold">
              {learningDocument.systemName}
            </span>
          </div>
          <div className="text-body-base w-1/6 text-start">
            {formatDate(learningDocument.updatedAt)}
          </div>
          <div className="text-body-base w-1/6 text-start">
            {learningDocument.user?.fullName}
          </div>
          <div className="text-body-base w-1/6 text-start">
            {status === "error" ? (
              <Tooltip
                direction="bottom"
                text={`エラー: ${error}`}
                className="text-xs"
              >
                <span className={statusMapper[status]?.color}>✕</span>
                <span className="ml-2">{statusMapper[status]?.text}</span>
              </Tooltip>
            ) : (
              <>
                <span className={statusMapper[status]?.color}>●</span>
                <span className="ml-2">{statusMapper[status]?.text}</span>
              </>
            )}
          </div>
        </button>

        <div className="flex gap-6 items-center justify-end w-1/12">
          <div className="relative flex items-center">
            <IconButton
              icon={{
                icon: "bsThreeDots",
                color: "text-blueGray-500",
                size: "1.5rem",
              }}
              srOnlyText="メニューを開く"
              onClick={handleOpen}
            />
            {isOpen && (
              <ListMenu
                items={menuItems}
                onClose={onClose}
                className="right-full mr-2"
              />
            )}
          </div>
        </div>
      </div>
      {isOpenDocumentPreview && (
        <DocumentPreviewModal
          onClose={onCloseDocumentPreview}
          path={learningDocument.path}
          extension={learningDocument.extension()}
          systemName={learningDocument.systemName}
        />
      )}
    </>
  );
};
