import { toast } from "react-toastify";

import { SingleValueType } from "@spesill/components/atoms";

import {
  useCurrentUser,
  useIncrementAiUsage,
  useLearningDocumentsWithUser,
  useSelectLearningDatabaseDropdownField,
  useStorageUploadLearningDocument,
  useTenantAiCallRestriction,
  useTenantLimitManager,
} from "@spesill/hooks";
import { apiClient } from "@spesill/libs/apiClient";

type PropsType = {
  systemName: string;
  currentDatabaseValue?: SingleValueType;
};
export const useRegisterFileToLearningDatabase = ({
  systemName,
  currentDatabaseValue,
}: PropsType) => {
  const {
    dropdownValue: dropdownValueLearningDatabase,
    dropdownSelectableValue: dropdownSelectableValueLearningDatabase,
    onChange: onChangeDropdownLearningDatabase,
  } = useSelectLearningDatabaseDropdownField(currentDatabaseValue);
  const { incrementAiCallCount } = useIncrementAiUsage();
  const { fetchDocumentsWithUser: refetch, learnDocuments } =
    useLearningDocumentsWithUser(String(dropdownValueLearningDatabase?.value));
  const { currentUser } = useCurrentUser();
  const { uploadFile, isUploading } = useStorageUploadLearningDocument(
    dropdownValueLearningDatabase?.value || "",
  );

  const { canCallAi } = useTenantAiCallRestriction();
  const { isUploadable } = useTenantLimitManager();

  const handleRegisterFile = async (blob: Blob) => {
    if (!dropdownValueLearningDatabase?.value) {
      toast.error("データベースを選択してください。");
      return;
    }
    if (!currentUser) return;

    if (!canCallAi) {
      toast.error(
        "AIの利用回数がプランの上限に達しました。プランをアップグレードしてください",
      );
      return;
    }

    try {
      const learningStatusToast = toast.loading(
        "AI学習中.. しばらくお待ちください",
        {
          theme: "dark",
        },
      );

      const canAddFile = await isUploadable(blob.size);
      if (!canAddFile) {
        return toast.error(
          "データ容量がプランの上限に達しました。プランをアップグレードしてください",
        );
      }

      await uploadFile({
        file: blob,
        systemName: systemName,
        fileName: systemName,
        onSuccess: async () => {
          await apiClient()
            .generate_vector_indices.$post({
              body: {
                tenant_id: currentUser.tenantId,
                group_id: dropdownValueLearningDatabase?.value,
              },
            })
            .then((res) => {
              const learningDocumentIds = res.status
                .filter((s) => s.status === "success")
                .map((s) => s.document_id);
              learnDocuments(learningDocumentIds).then(() => {
                toast.update(learningStatusToast, {
                  render: "AI学習が完了しました。",
                  type: "success",
                  isLoading: false,
                  autoClose: 2000,
                });
                refetch();
              });
              incrementAiCallCount("generateVectorIndices");
            });
        },
      });
    } catch (error) {
      toast.error(
        "学習データベースへの登録に失敗しました。時間を開けてご利用いただくか、サポートにお問い合わせください。",
      );
      throw error;
    }
  };

  return {
    dropdownValueLearningDatabase,
    dropdownSelectableValueLearningDatabase,
    onChangeDropdownLearningDatabase,
    handleRegisterFile,
    isRegistering: isUploading,
  };
};
